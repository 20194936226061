import axios from "axios";
import moment from "moment";

const API = axios.create({ baseURL: "https://api.flashteller.com", withCredentials: true });
//const API = axios.create({ baseURL: "http://localhost:5000", withCredentials: true });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("profile")) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token}`;
    }
    return req;
});

export const getAgentInfo = (agentid, merchant, token) => API.post("/cashier/getagentinfo", { agentid: agentid, merchant, token });

export const getAgentInfoSecurity = (agentid, merchant, token) => API.post("/cashier/getagentsecurity", { agentId: agentid, merchant: merchant, token });

export const updateAgentSettings = (info, isPassword, token) =>
    API.post("/cashier/updatesettings", {
        info: info,
        isPassword: isPassword,
        token,
    });

export const updateAgentSecurity = (info, token) => API.post("/cashier/updatesecurity", { info: info, token });

export const verifyTwoFactCode = (info, token) => API.post("/cashier/verifytwofactorcode", { info: info, token });

export const agentWithdrawal = (agentid, merchant, txid_hash, token) =>
    API.post("/cashier/agentwithdrawal", {
        agentid: agentid,
        merchant: merchant,
        txid_hash: txid_hash,
        token,
    });

export const getDeposits = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token) =>
    API.post("/cashier/getdeposits", {
        id: agentId,
        merchant,
        start: pstart,
        length: plength,
        startDate,
        search,
        status,
        cryptoCurrency,
        token,
        fromDate,
        toDate,
    });

export const getRequestWithdrawals = (agentId, merchant, pstart, plength, startDate, search, token) =>
    API.post("/cashier/getrequestwithdrawals", {
        id: agentId,
        merchant,
        start: pstart,
        length: plength,
        startdate: startDate,
        search: search,
        token,
    });

export const getProcessWithdrawals = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token) =>
    API.post("/cashier/getprocesswithdrawals", {
        id: agentId,
        merchant,
        start: pstart,
        length: plength,
        startdate: startDate,
        search: search,
        status: status,
        cryptoCurrency: cryptoCurrency,
        fromDate, 
        toDate,
        token,
    });

export const setWithdrawalRequestAnswer = (withdrawalId, answer, token) =>
    API.post(`/cashier/setrequestwithdrawalanswer`, {
        withdrawalId,
        answer,
        token,
    });

export const getWithdrawals = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token) =>
    API.post("/cashier/getwithdrawals", {
        id: agentId,
        merchant,
        start: pstart,
        length: plength,
        startdate: startDate,
        search, 
        status, 
        cryptoCurrency,
        fromDate,
        toDate,
        token,
    });

export const insertNewAgent = (data) => {
    try {
        return API.post(`/cashier/addagent`, data);
    } catch (error) {
        return error;
    }
};

export const getPlayerWithdrawals = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token) =>
    API.post("/cashier/getplayerwithdrawals", {
        id: agentId,
        merchant,
        start: pstart,
        length: plength,
        startdate: startDate,
        search: search,
        status: status,
        cryptoCurrency: cryptoCurrency,
        fromDate, 
        toDate,
        token,
    });

export const monthDeposits = (tz, agentId) => {
    try {
        moment.updateLocale("en", {
            week: {
                dow: 1, // Monday is the first day of the week.
            },
        });
        const query = {
            start_date: moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"),
            end_date: moment().endOf("year").format("YYYY-MM-DD HH:mm:ss"),
            is_datefilter: "1",
            tz: tz,
        };
        return API.get(`/cashier/depositsbyagentmonth/${agentId}?start_date=${query.start_date}&end_date=${query.end_date}&is_datefilter=${query.is_datefilter}&tz=${query.tz}`);
    } catch (error) {
        return error;
    }
};

export const hourDeposits = (tz, agentId) => {
    try {
        moment.updateLocale("en", {
            week: {
                dow: 1, // Monday is the first day of the week.
            },
        });
        const query = {
            start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            is_datefilter: "1",
            tz: tz,
        };
        return API.get(`/cashier/depositsbyagenthour/${agentId}?start_date=${query.start_date}&end_date=${query.end_date}&is_datefilter=${query.is_datefilter}&tz=${query.tz}`);
    } catch (error) {
        return error;
    }
};

export const weekDeposits = (weeknumber, agentId) => {
    try {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        moment.updateLocale("en", {
            week: {
                dow: 1, // Monday is the first day of the week.
            },
        });
        const query = {
            start_date: moment().subtract(weeknumber, "weeks").startOf("week").subtract(6, "hour").format("YYYY-MM-DD HH:mm:ss"),
            end_date: moment().subtract(weeknumber, "weeks").endOf("week").subtract(6, "hour").format("YYYY-MM-DD HH:mm:ss"),
            is_datefilter: "1",
            tz: tz,
        };
        return API.get(`/cashier/depositsbyagentweek/${agentId}?start_date=${query.start_date}&end_date=${query.end_date}&is_datefilter=${query.is_datefilter}&tz=${query.tz}`);
    } catch (error) {
        return error;
    }
};

export const getAgentsMessage = (agentId) => {
    try {
        return API.get(`/cashier/agentformessage/${agentId}`);
    } catch (error) {
        return error;
    }
};

export const getMessages = (agentId, pstart, plength) => {
    try {
        const query = {
            start: pstart,
            length: plength,
            search: "",
        };
        return API.get(`/cashier/getmessages/${agentId}?start=${query.start}&length=${query.length}&search=${query.search}`);
    } catch (error) {
        return error;
    }
};

export const getSentMessages = (agentId, pstart, plength) => {
    try {
        const query = {
            start: pstart,
            length: plength,
            search: "",
        };
        return API.get(`/cashier/getsentmessages/${agentId}?start=${query.start}&length=${query.length}&search=${query.search}`);
    } catch (error) {
        return error;
    }
};

export const deleteMessage = (messageId) => {
    try {
        return API.get(`/cashier/deletemessage/${messageId}`);
    } catch (error) {
        return error;
    }
};

export const sendMessage = (message) => {
    try {
        return API.post(`/cashier/sendmessage`, message);
    } catch (error) {
        return error;
    }
};

export const getCustomers = (agentId, merchant, pstart, plength, startDate, search, token) => {
    try {
        return API.post("/cashier/getcustomers", {
            id: agentId,
            merchant,
            start: pstart,
            length: plength,
            startdate: startDate,
            search: search,
            token,
        });
    } catch (error) {
        return error;
    }
};

export const getCustomer = (id, token) => {
    try {
        return API.post("/cashier/getonecustomer", {
            id: id,
            token,
        });
    } catch (error) {
        return error;
    }
};

export const getAllAgentsList = (agentId, pstart, plength, startDate, search) => {
    try {
        return API.get(`/cashier/getallagentslist/${agentId}?start=${pstart}&length=${plength}&startdate=${startDate}&search=${search}`);
    } catch (error) {
        return error;
    }
};

export const updateCustomer = (customer, id, token) => API.post("/cashier/updatecustomer", { customer, id, token });
