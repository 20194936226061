import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

//Core
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { Button, IconButton, InputAdornment, TableContainer, TextField, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";

//Styles
import styles from "../../assets/styles/components/agentsListStyle";

//Functions
import { getWithdrawals } from "../../actions/agent";
import { LOGIN } from "../../constants/actionTypes";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DateRange from "../Dialogs/DateRange"

const useStyles = makeStyles(styles);

const options = [
    {
        status: "all",
        description: "All",
    },
    {
        status: "processed",
        description: "Accepted",
    },
    {
        status: "declined",
        description: "Rejected",
    },
    {
        status: "cancel",
        description: "Canceled",
    },
    {
        status: "expired",
        description: "Expired",
    },
];

const optionsCrypto = [
    {
        crypto: "all",
        description: "All",
    },
    {
        crypto: "BTC",
        description: "BTC",
    },
    {
        crypto: "LN",
        description: "Lightning",
    },
    {
        crypto: "ETH",
        description: "ETH",
    },
    {
        crypto: "USDT",
        description: "USDT",
    },
    {
        crypto: "USDC",
        description: "USDC",
    },
];

const Withdrawals = (props) => {
    const classes = useStyles();
    const [totals, setTotals] = useState({});
    const { agentId, merchant } = props;
    const dispatch = useDispatch();
    const now = moment();
    const history = useHistory();
    const token = useSelector((state) => state?.login?.loginData?.token);

    let subTotCryptoAmount = 0;
    let subTotUSD = 0;
    let subTotFees = 0;
    let subTotTotal = 0;

    let start = 0;
    let startDate = now.startOf("day");

    const [length, setLength] = useState(10);
    const [page, setPage] = useState(0);
    const [withdrawalsList, setWithdrawalsList] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [status, setStatus] = useState("all");
    const [search, setSearch] = useState("");
    const [cryptoCurrency, setCryptoCurrency] = useState("all");
    const [textDateButton, setTextDateButton] = useState("Last 30 Days");
    const [openDateRange, setOpenDateRange] = useState({ isOpen: false, title: "", subTitle: "" });
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 29)));
    const [toDate, setToDate] = useState(new Date(new Date()));
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);

    const handleCopy = (trid) => {
        navigator.clipboard.writeText(trid);
    };

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tableHead = ["Date", "Agent", "Status", "Type", "Network", "Crypto Amount", "Exchange", "$USD", "Fees", "Total", "Transaction Id"];

    useEffect(() => {
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    }, [start, length, dispatch]);

    const searchChange = (e) => {
        e.preventDefault();
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, e.target.value, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const onChangeStatus = (e, v) => {
        setStatus(v.status);
        e.preventDefault();
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, search, v.status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const onChangeStatusCrypto = (e, v) => {
        setCryptoCurrency(v.crypto);
        e.preventDefault();
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, search, status, v.crypto, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };
    const processByDateRange = (item) => {
        //console.log("DATA RANGE", item);
        if (item.key === "selection") {
            setTextDateButton("Selection");
        } else {
            setTextDateButton(item.key);
        }
        setOpenDateRange({ ...openDateRange, isOpen: false });
        setFromDate(item.startDate);
        setToDate(item.endDate);
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, item.startDate, item.endDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const AllDataToExcel = async () => {
        dispatch(
            getWithdrawals(agentId, merchant, start, -1, startDate, search, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    var dataArray = transferToArray(res.data);
                    var newWorkbook = XLSX.utils.book_new();

                    var newSheet = XLSX.utils.json_to_sheet(dataArray);

                    XLSX.utils.book_append_sheet(newWorkbook, newSheet, "Sheet1");
                    const excelBuffer = XLSX.write(newWorkbook, { bookType: "xlsx", type: "array" });
                    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
                    saveAs(blob, "deposits.xlsx");
                }
            })
        );
    };

    const transferToArray = (lista) => {
        const dataToExcel = [];
        const list = lista;
        var tot = 0;
        var totUsd = 0;
        var rowdat = {};

        list.forEach((row, index) => {
            rowdat = {
                id: index,
                RequestDate: `${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toTimeString().substring(0, 5)}`,
                Customer: row.player,
                merchantName: row.merchantName,
                Agent: row.agent,
                Type: row.isLN === true && row.cryptoCurrency === "BTC" ? "Lightning" : row.isLN === false && row.cryptoCurrency === "BTC" ? "BTC Mainnet" : row.cryptoCurrency,
                Network: row.isLN === true && row.network === "BITCOIN" ? "BITCOIN" : row.network === "ERC20" ? "ETHEREUM" : "TRON",
                Status: row.depositStatus === "pending" ? "Pending" : row.depositStatus === "processed" ? "Accepted" : row.depositStatus === "declined" ? "Rejected" : row.depositStatus === "cancel" ? "Canceled" : row.depositStatus === "expired" ? "Expired" : row.depositStatus,
                CryptoAmount: parseFloat(row.cryptoAmount).toFixed(row.cryptoCurrency === "BTC" ? 8 : row.cryptoCurrency === "ETH" ? 18 : row.cryptoCurrency === "USDT" ? 6 : row.cryptoCurrency === "USDC" ? 6 : 2),
                Exchange: row.exchangeRate,
                USD: row.currencyAmount.toFixed(2),
                Fees: row.isLN === true ? parseFloat(row.feeMerchant + row.feeChain).toFixed(2) : parseFloat(row.feeMerchant * row.exchangeRate).toFixed(2),
                Total: row.isLN === true || row.isLN === "true" ? parseFloat(row.currencyAmount - (row.feeMerchant + row.feeChain)).toFixed(2) : parseFloat(row.currencyAmount - parseFloat(row.feeMerchant * row.exchangeRate)).toFixed(2),
                TransactionId: row.txid_hash,
            };
            if (!isNaN(rowdat.Total)) {
                tot += parseFloat(rowdat.Total);
            }
            if (!isNaN(rowdat.USD)) {
                totUsd += parseFloat(rowdat.USD);
            }

            dataToExcel.push(rowdat);
        });
        rowdat = {
            id: "",
            RequestDate: "",
            Customer: "",
            merchantName: "",
            Agent: "",
            Type: "",
            Network: "",
            Status: "",
            CryptoAmount: "",
            Exchange: "",
            USD: `${parseFloat(totUsd).toFixed(2)}`,
            Fees: "",
            Total: `${parseFloat(tot).toFixed(2)}`,
            TransactionId: "",
        };
        dataToExcel.push(rowdat);
        //setWithdrawals(dataToExcel);
        return dataToExcel;
        //console.log("TRANSFER RESULT", dataToExcel);
    };

    const opDateRange = (e) => {
        e.preventDefault();
        setState([
            {
                startDate: new Date(),
                endDate: null,
                key: "selection",
            },
        ]);
        //console.log("on open", openDateRange.isOpen);
        setOpenDateRange({
            isOpen: true,
            subTitle: "",
        });
    };

    if (withdrawalsList.length === 0) {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"bydate"}>
                                <Button onClick={opDateRange} className={classes.datebutton}>
                                    {textDateButton}
                                </Button>
                            </TableCell>
                            <TableCell colSpan={1} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                <TextField
                                    label="Search"
                                    style={{ width: 200 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={searchChange}
                                />
                            </TableCell>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"status"}>
                                <Autocomplete id="status" style={{ width: 125 }} options={options} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                            </TableCell>
                            <TableCell colSpan={6} className={classes.tableCell + " " + classes.tableHeadCell} key={"cryptoCurrency"}>
                                <Autocomplete id="crypto" style={{ width: 125 }} options={optionsCrypto} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Crypto" margin="normal" />} onChange={onChangeStatusCrypto} />
                            </TableCell>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"toexcel"}>
                                <Button onClick={AllDataToExcel} className={classes.datebutton}>
                                    {"Export Data"}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell colSpan={11} key={"norecords1"}>
                                <div style={{ textAlign: "center" }}>No records found</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <DateRange openDateRange={openDateRange} setOpenDateRange={setOpenDateRange} state={state} setState={setState} processByDateRange={processByDateRange} />
            </TableContainer>
        );
    } else {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"bydate"}>
                            <Button onClick={opDateRange} className={classes.datebutton}>
                                {textDateButton}
                            </Button>
                        </TableCell>
                        <TableCell colSpan={1} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                            <TextField
                                label="Search"
                                style={{ width: 200 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={searchChange}
                            />
                        </TableCell>
                        <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"status"}>
                            <Autocomplete id="status" style={{ width: 125 }} options={options} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                        </TableCell>
                        <TableCell colSpan={4} className={classes.tableCell + " " + classes.tableHeadCell} key={"cryptoCurrency"}>
                            <Autocomplete id="crypto" style={{ width: 125 }} options={optionsCrypto} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Crypto" margin="normal" />} onChange={onChangeStatusCrypto} />
                        </TableCell>
                        <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"toexcel"}>
                            <Button onClick={AllDataToExcel} className={classes.datebutton}>
                                {"Export Data"}
                            </Button>
                        </TableCell>
                    </TableRow>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {withdrawalsList.map((withdrawal, index) => {
                            subTotCryptoAmount += withdrawal.isLN === "true" ? parseFloat(withdrawal.cryptoAmount / 100000000) : parseFloat(withdrawal.cryptoAmount);
                            subTotUSD += withdrawal.currencyAmount;
                            subTotFees += parseFloat(withdrawal.feeMerchantAmount + withdrawal.feeAmountChain);
                            subTotTotal += withdrawal.isLN === true ? parseFloat(withdrawal.currencyAmount + (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain)) : parseFloat(withdrawal.currencyAmount - (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain));
                            return (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell}>{moment(withdrawal.createdAt).format("MM/DD/YYYY HH:mm")}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.agent}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.payoutStatus}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.isLN === true && withdrawal.cryptoCurrency === "BTC" ? "Lightning" : withdrawal.isLN === false && withdrawal.cryptoCurrency === "BTC" ? "BTC Mainnet" : withdrawal.cryptoCurrency}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.network === "BITCOIN" || withdrawal.cryptoCurrency === "BTC" ? "BITCOIN" : withdrawal.network === "ERC20" ? "ETHEREUM" : "TRON"}</TableCell>
                                    <TableCell className={classes.tableCell}>{`${parseFloat(withdrawal.cryptoAmount).toFixed(withdrawal.cryptoCurrency === "BTC" ? 8 : withdrawal.cryptoCurrency === "ETH" ? 18 : withdrawal.cryptoCurrency === "USDT" ? 6 : withdrawal.cryptoCurrency === "USDC" ? 6 : 2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${withdrawal.exchangeRate}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.currencyAmount).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.feeMerchantAmount + withdrawal.feeAmountChain).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.isLN === true ? withdrawal.currencyAmount + withdrawal.feeMerchantAmount + withdrawal.feeAmountChain : withdrawal.currencyAmount - (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain)).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {`${withdrawal.txid_hash ? withdrawal.txid_hash.toString().substring(0, 6) + "..." : ""}`}
                                        {withdrawal.txid_hash ? (
                                            <Tooltip title={withdrawal.txid_hash}>
                                                <IconButton aria-label="delete" onClick={() => handleCopy(withdrawal.txid_hash)}>
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow key={"subTotals"} className={classes.tableRow}>
                            <TableCell className={classes.tableCellSubTotal}>{"SubTotal"}</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotUSD).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotFees).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotTotal).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        {!isNaN(totals.mainTotalBTC) ? (
                            <>
                                <TableRow key={"grandTotal"} className={classes.tableRow}>
                                    <TableCell className={classes.tableCellGrandotal}>{"Grand Total"}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalUSD).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalFees).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalTotal).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                </TableRow>
                            </>
                        ) : null}
                    </TableBody>
                </Table>
                <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                <DateRange openDateRange={openDateRange} setOpenDateRange={setOpenDateRange} state={state} setState={setState} processByDateRange={processByDateRange} />
            </TableContainer>
        );
    }
};

export default Withdrawals;
